import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { createBrowserHistory } from 'history';
import { notification } from 'antd';
import FiltrosAvancados from '../components/FiltrosAvancados/FiltrosAvancados.component';
import ShowFiltros from '../components/ShowFiltros/ShowFiltros.component';
import TableDinamic from '../../Modulos/components/Grid/TableDinamic.component';
import {
  getColunasFiltro,
  obterDadosFiltroAvancado,
} from '../services/buscaAvancada.service';
import * as ModuloVisaoService from '../../Modulos/services/moduloVisao.service';

import {
  obterConfiguracoes,
  obterConfiguracoesGerais,
} from '../../Modulos/services/moduloVisao.service';
import { PARAMS_FILTERS_GRID_BUSCA_AVANCADA } from '~/modules/Helper/searchParamsFilters';
import { NUMERO_QUANTIDADE_REGISTROS } from '~/modules/Helper/numeroQuantidadeRegistros';
import { getAndFormatSearchParamsBuscaAvancada } from '~/modules/Helper/searchString-helper';
import { parseToQueryString } from '~/modules/Helper/querystring-helper';
import {
  Row,
  Col,
  Card,
  PageTitle,
  FormV3 as Form,
  Button,
  ButtonGoBack,
} from '~/components';
import { useUrls } from '~/contexts/url.context';
import { TIPO_VISAO } from '~/modules/Helper/tipo-visao';
import { TIPO_COLUNA } from '~/modules/Helper/tipoColuna';
import Totalizadores from '../../Modulos/components/Totalizadores/Totalizadores.component';

function BuscaAvancada({ match, history, form }) {
  const { empilhaUrl, obterUrlPaginaAnterior } = useUrls();
  const [colunasFiltro, setColunasFiltro] = useState();
  const [configuracoesVisao, setConfiguracoesVisao] = useState(null);
  const [filtros, setFiltros] = useState([]);
  const [moduloVisao, setModuloVisao] = useState(null);

  const [listaColunaOrdem, setListaColunaOrdem] = useState([]);
  const [quantidadeRegistros, setQuantidadeRegistros] = useState(null);
  const [pagina, setPagina] = useState(null);
  const [urlVisaoAnalitica, setUrlVisaoAnalitica] = useState(null);
  const [urlVisaoAgrupamento, setUrlVisaoAgrupamento] = useState(null);
  const [dataUltimaAtualizacao, setDataUltimaAtualizacao] = useState(null);
  const [possuiArquivoDigital, setPossuiArquivoDigital] = useState(false);
  const [exibirArquivosForm, setExibirArquivosForm] = useState(false);
  const [
    filtroPesquisaArquivosDigitais,
    setFiltroPesquisaArquivosDigitais,
  ] = useState(null);
  const [FiltroHabilitado, setFiltroHabilitado] = useState(null);
  const [chavesPeriodicidade, setChavesPeriodicidade] = useState([]);

  const { modulo, visao } = match.params;

  const browserHistory = createBrowserHistory();

  const routeParams = new URLSearchParams(history.location.search);

  const arrayFonteDados = [];

  const [valoresParamsFiltros, setValoresParamsFiltros] = useState([]);
  const [totalizadores, setTotalizadores] = useState([]);

  const paginacaoGridParam = Number(routeParams.get('pagina'));
  const quantidadeRegistrosParam = routeParams.get('quantidaderegistros');
  const listaColunaOrdemParam = JSON.parse(routeParams.get('listacolunaordem'));

  function setSearchParams() {
    setPagina(paginacaoGridParam || 1);
    setQuantidadeRegistros(
      NUMERO_QUANTIDADE_REGISTROS.includes(quantidadeRegistrosParam)
        ? quantidadeRegistrosParam
        : '10'
    );
    if (listaColunaOrdemParam?.length)
      setListaColunaOrdem(listaColunaOrdemParam);
  }

  function voltarPaginaAnterior() {
    const { urlBase, queryParam } = obterUrlPaginaAnterior();

    if (urlBase && queryParam) {
      history.push({ pathname: urlBase, search: queryParam });
    } else {
      history.push(`/`);
    }
  }

  function handleTableChange(pagination, filters, sorter) {
    if (!Array.isArray(sorter))
      return setListaColunaOrdem([
        {
          ColunaOrdem: sorter.field,
          TipoOrdem: sorter.order,
          Ordem: 1,
        },
      ]);
    const maxOrdem = Math.max(...listaColunaOrdem?.map(item => item.Ordem));

    const novaListaOrdenacaoColuna = sorter?.map((s, index) => {
      const itemOrdenacao = listaColunaOrdem.find(
        (ordenacao, idx) =>
          s.columnKey === ordenacao.ColunaOrdem && index === idx
      );
      if (itemOrdenacao) {
        return {
          ...itemOrdenacao,
          TipoOrdem: s.order,
        };
      }
      return {
        ColunaOrdem: s.field,
        TipoOrdem: s.order,
        Ordem: maxOrdem ? maxOrdem + 1 : 1,
      };
    });

    setListaColunaOrdem(novaListaOrdenacaoColuna);
  }

  const obterConfiguracoesVisao = useCallback(async () => {
    const response = await obterConfiguracoes(modulo, visao);
    if (response?.data) setConfiguracoesVisao(response.data);
  }, [modulo, visao]);

  function getFonteDadosColunas() {
    if (colunasFiltro?.Grupos?.length > 0) {
      colunasFiltro.Grupos.forEach(grupo => {
        grupo.Colunas.forEach(coluna => {
          arrayFonteDados.push(coluna.FonteDados);
        });
      });
    }
  }

  const obterExerciciosEVisaoAnalitica = useCallback(async () => {
    const responseConfiguracoesGerais = await obterConfiguracoesGerais(modulo);

    if (responseConfiguracoesGerais?.data) {
      const {
        NomeVisaoAnalitica,
        NomeVisaoAgrupamento,
        PossuiArquivoDigital,
        DataUltimaSicronizacaoModulo,
        FiltroHabilitado,
      } = responseConfiguracoesGerais?.data;

      if (NomeVisaoAnalitica) setUrlVisaoAnalitica(NomeVisaoAnalitica);

      if (NomeVisaoAgrupamento) setUrlVisaoAgrupamento(NomeVisaoAgrupamento);

      if (DataUltimaSicronizacaoModulo)
        setDataUltimaAtualizacao(DataUltimaSicronizacaoModulo);

      if (FiltroHabilitado) setFiltroHabilitado(FiltroHabilitado);

      if (PossuiArquivoDigital !== null && PossuiArquivoDigital !== undefined)
        setPossuiArquivoDigital(PossuiArquivoDigital);
    }
  }, [modulo]);

  function obterChavesPeriocidadeDaConfiguracao() {
    const colunasPeriodicidade = configuracoesVisao.ChavesPeriodicidades;

    setChavesPeriodicidade(arrayAnterior => [
      ...arrayAnterior,
      ...colunasPeriodicidade,
    ]);
  }

  function obterValoresChavesPeriodicidadePorLinha(linhaGrid) {
    const valores = chavesPeriodicidade.map(colunaGrid => ({
      campo: colunaGrid.FonteDados,
      valor: linhaGrid[colunaGrid.FonteDados],
      tipo: colunaGrid.TipoValor,
    }));
    return valores;
  }

  const obterColunaLink = useCallback(() => {
    if (!(configuracoesVisao && configuracoesVisao.VisaoColunas)) return null;

    return configuracoesVisao.VisaoColunas.find(x => x.ColunaLink);
  }, [configuracoesVisao]);

  const exibirAnexos = useCallback(row => {
    const valoresChavesPeriodicidade = obterValoresChavesPeriodicidadePorLinha(
      row
    );

    const chavePesquisa = {
      ChaveModulo: modulo,
      Exercicio: row.Exercicio,
      Periodicidade: 'MENSAL',
      Periodo: row.Mes,
      ChavesPeriodicidade: valoresChavesPeriodicidade.map(item => ({
        Campo: item.campo,
        Valor: item.valor,
        TipoValor: item.tipo,
      })),
    };

    setFiltroPesquisaArquivosDigitais(chavePesquisa);
    setExibirArquivosForm(true);
  });

  function redirecionarDetalheVisaoAnalitica(
    valoresChavesPeriodicidade,
    exercicio,
    mes
  ) {
    const colunasPeriodicidadeCampoValor = {};

    valoresChavesPeriodicidade.forEach(item => {
      colunasPeriodicidadeCampoValor[item.campo] = item.valor;
    });

    const data = {
      exercicio,
      periodicidade: 'MENSAL',
      periodo: mes,

      ...colunasPeriodicidadeCampoValor,
    };

    const queryString = parseToQueryString(data);

    empilhaUrl(window.location.hash.split('#')[1]);

    history.push({
      pathname: `detalhevisao`,
      state: {
        pagina,
        quantidadeRegistros,
        listaColunaOrdem: listaColunaOrdem,
      },
      search: queryString,
    });
  }

  const buscaDadosGrid = useCallback(() => {
    if (!configuracoesVisao) {
      return;
    }

    const data = {
      ChaveModulo: modulo,
      NomeVisao: visao,
      Filtros: filtros,
      Periodicidade: null,
      Periodo: null,
      Exercicio: null,
      FiltroRedirecionaVisao: null,
      Pagina: pagina,
      QuantidadeRegistros: quantidadeRegistros,
      Ordenacao: listaColunaOrdem,
    };

    obterDadosFiltroAvancado(data)
      .then(res => {
        setModuloVisao(res.data);
        return null;
      })
      .catch(() => {
        setModuloVisao({
          QuantidadePaginas: 0,
          QuantidadeRegistros: 0,
          Valores: [],
        });
      });

    const dataQueryString = {
      filtros,
      pagina,
      quantidaderegistros: quantidadeRegistros,
      listacolunaordem: listaColunaOrdem
        ? JSON.stringify(listaColunaOrdem)
        : null,
    };

    const searchParams = getAndFormatSearchParamsBuscaAvancada(
      dataQueryString,
      PARAMS_FILTERS_GRID_BUSCA_AVANCADA
    );

    browserHistory.replace(
      `/#/dinamico/${modulo}/${visao}/busca?${searchParams}`,
      history.location.state
    );
  }, [
    modulo,
    pagina,
    quantidadeRegistros,
    visao,
    listaColunaOrdem,
    configuracoesVisao,
    filtros,
  ]);

  async function getColunas() {
    const response = await getColunasFiltro(modulo);
    setColunasFiltro(response);
  }

  function getParamsUrlFonteDadosColunas(array) {
    const arrayAuxiliar = [];

    array.forEach(fonteDados => {
      let valorParam = routeParams.get(`${fonteDados}`);

      if (valorParam !== null) {
        valorParam = valorParam.split(',');

        const objetoFiltro = {
          Campo: fonteDados,
          Valor:
            valorParam[valorParam.length - 1] === '5'
              ? `${valorParam[0]},${valorParam[1]}`
              : valorParam[0],
          TipoValor: valorParam[valorParam.length - 1],
        };

        arrayAuxiliar.push(objetoFiltro);
      }
    });

    setValoresParamsFiltros(arrayAuxiliar);
  }

  function transformaFormEmObjetoRequisicao(valores) {
    const chavesFiltro = Object.keys(valores);

    const novoFiltro = [];

    function montaNovoFiltro(filtro, isBool) {
      novoFiltro.push({
        Campo: valores[`${filtro}`].Campo,
        // eslint-disable-next-line no-nested-ternary
        Valor: isBool
          ? valores[`${filtro}`].Valor === 1
            ? 'Sim'
            : 'Não'
          : valores[`${filtro}`].Valor,
        TipoValor: valores[`${filtro}`].TipoValor,
      });
    }

    chavesFiltro.forEach(filtro => {
      if (
        valores[`${filtro}`] !== undefined &&
        valores[`${filtro}`].TipoValor !== TIPO_COLUNA.BOOLEANO
      ) {
        if (valores[`${filtro}`].Valor.trim() !== '') {
          montaNovoFiltro(filtro);
        }
      } else if (valores[`${filtro}`] !== undefined) {
        if (valores[`${filtro}`].Valor !== '') {
          montaNovoFiltro(filtro, true);
        }
      }
    });

    return novoFiltro;
  }

  function handlePesquisa() {
    form.validateFields(async function _(err, values) {
      if (!err) {
        const filtrosRequisicao = transformaFormEmObjetoRequisicao(values);

        if (filtrosRequisicao.length > 0) {
          setFiltros(filtrosRequisicao);
        } else {
          notification.error({
            message: 'Preencha ao menos um filtro para continuar',
          });
        }
      }
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    handlePesquisa();
  }

  function removeTodosOsFiltros() {
    setFiltros([]);
    setValoresParamsFiltros([]);
    setTotalizadores([]);
    setPagina(1);
    setQuantidadeRegistros('10');
    setListaColunaOrdem([]);

    browserHistory.replace(
      `/#/dinamico/${modulo}/${visao}/busca`,
      history.location.state
    );
  }

  async function obterTotalizadores() {
    const data = {
      ChaveModulo: modulo,
      NomeVisao: visao,
      Filtros: filtros,
      Periodicidade: 'MENSAL',
      BuscaAvancada: true,
    };

    const ColunasTotalizadoras = await ModuloVisaoService.obterTotalizadores(
      data
    );

    setTotalizadores(ColunasTotalizadoras.data);
  }

  const Grid = useMemo(() => {
    return configuracoesVisao?.VisaoColunas &&
      moduloVisao?.Valores &&
      filtros.length > 0 ? (
      <TableDinamic
        moduloVisao={moduloVisao}
        handleTableChange={handleTableChange}
        configuracao={configuracoesVisao}
        quantidadeRegistros={quantidadeRegistros}
        setQuantidadeRegistros={setQuantidadeRegistros}
        pagina={pagina}
        setPagina={setPagina}
        modulo={modulo}
        visao={visao}
        filtro={filtros}
        periodicidadeSelecionada={null}
        periodoSelecionado={null}
        exercicioSelecionado={null}
        colunaFiltroRedirecionaVisao={null}
        valorFiltroRedirecionaVisao={null}
        tipoValorFiltroRedirecionaVisao={null}
        redirecionarDetalheVisaoAnalitica={redirecionarDetalheVisaoAnalitica}
        urlVisaoAnalitica={urlVisaoAnalitica}
        urlVisaoAgrupamento={urlVisaoAgrupamento}
        listaColunaOrdem={listaColunaOrdem}
        obterValoresChavesPeriodicidadePorLinha={
          obterValoresChavesPeriodicidadePorLinha
        }
        dataUltimaAtualizacao={dataUltimaAtualizacao}
        redirecionarVisaoAnalitica={null}
        possuiArquivoDigital={possuiArquivoDigital}
        exibirAnexos={exibirAnexos}
        filtroPesquisaArquivosDigitais={filtroPesquisaArquivosDigitais}
        exibirArquivosForm={exibirArquivosForm}
        setFiltroPesquisaArquivosDigitais={setFiltroPesquisaArquivosDigitais}
        setExibirArquivosForm={setExibirArquivosForm}
        buscaAvancada
      />
    ) : (
      <></>
    );
  }, [
    listaColunaOrdem,
    configuracoesVisao,
    possuiArquivoDigital,
    filtros,
    modulo,
    moduloVisao,
    pagina,
    quantidadeRegistros,
    urlVisaoAnalitica,
    urlVisaoAgrupamento,
    visao,
    filtroPesquisaArquivosDigitais,
    exibirArquivosForm,
    exibirAnexos,
    dataUltimaAtualizacao,
  ]);

  useEffect(() => {
    if (
      FiltroHabilitado === false ||
      (configuracoesVisao && configuracoesVisao?.Tipo !== TIPO_VISAO.ANALITICO)
    )
      history.push('/notfound');
  }, [FiltroHabilitado, configuracoesVisao]);

  useEffect(() => {
    getColunas();
    obterConfiguracoesVisao();
    obterExerciciosEVisaoAnalitica();
    setSearchParams();
  }, [obterConfiguracoesVisao, obterExerciciosEVisaoAnalitica]);

  useEffect(() => {
    getFonteDadosColunas();
    getParamsUrlFonteDadosColunas(arrayFonteDados);
  }, [colunasFiltro]);

  useEffect(() => {
    if (configuracoesVisao) obterChavesPeriocidadeDaConfiguracao();
  }, [configuracoesVisao]);

  useEffect(() => {
    if (filtros.length > 0) {
      buscaDadosGrid();
      obterTotalizadores();
    }
  }, [buscaDadosGrid]);

  return (
    <>
      <PageTitle
        titulo={`Busca Avançada ${configuracoesVisao?.Titulo ? configuracoesVisao?.Titulo : ''
          }`}
      />
      {filtros.length === 0 ? (
        <Card>
          <Form onSubmit={handleSubmit} layout="vertical">
            <Row>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Row style={{ marginBottom: 16 }} gutter={16}>
                  <FiltrosAvancados
                    filtros={colunasFiltro}
                    form={form}
                    paramsFiltros={valoresParamsFiltros}
                    handlePesquisa={handlePesquisa}
                  />
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Row style={{ justifyContent: 'left' }}>
                  <ButtonGoBack OnClick={voltarPaginaAnterior} />
                </Row>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Row style={{ justifyContent: 'right' }}>
                  <Button
                    type="primary"
                    style={{
                      float: 'right',
                      width: '300px',
                      marginBottom: 16,
                    }}
                    htmlType="submit"
                  >
                    Filtrar
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      ) : (
        <></>
      )}

      {totalizadores?.length > 0 && (
        <Totalizadores totalizadores={totalizadores} />
      )}

      <ShowFiltros
        filtros={filtros}
        colunasFiltro={colunasFiltro}
        setFiltros={setFiltros}
        form={form}
        removeTodosOsFiltros={removeTodosOsFiltros}
      />

      {Grid}

      {filtros.length > 0 ? (
        <Row style={{ marginTop: '15px' }}>
          <Button onClick={() => removeTodosOsFiltros()}>Voltar</Button>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
}

export default Form.create({
  name: 'FormBuscaAvancada',
})(BuscaAvancada);
