import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  PageTitle,
  Divider,
  List,
  Card,
  Col,
  Row,
  ButtonGoBack,
} from '~/components';
import { useMenus } from '~/contexts/menu.context';
import { TIPO_MENU_PORTAL } from '~/modules/Helper/tipoMenuPortal';
import * as ModuloService from '../../../services/modulos.service';
import { CHAVES_MODULO } from '../../Helper/chave-modulo-constants';

function transformarJson(menu) {
  return menu.map(item => {
    const novosSubItens = [];

    function extrairSubItens(subItens) {
      subItens.forEach(subItem => {
        novosSubItens.push({ ...subItem, SubItens: [] });
        if (subItem.SubItens.length > 0) {
          extrairSubItens(subItem.SubItens);
        }
      });
    }

    extrairSubItens(item.SubItens);
    return { ...item, SubItens: novosSubItens };
  });
}

function Sumario() {
  const history = useHistory();
  const { menus } = useMenus();
  const corTema = useSelector(state => state.configuration.corTema);
  const [menusTransformados, setMenusTransformados] = useState([]);

  function handleRedirect(item) {
    if (!item.URI) return;
    if (item.TipoMenuPortal === TIPO_MENU_PORTAL.EXTERNO) {
      window.open(item.URI);
    } else {
      history.push(item.URI);
    }
  }

  function goBack() {
    history.push('/');
  }

  useEffect(() => {
    ModuloService.verificaModuloHabilitado(CHAVES_MODULO.SUMARIO);
    setMenusTransformados(transformarJson(menus));
  }, [menus]);

  return (
    <>
      <PageTitle titulo="Sumário" />
      <Col>
        <Card>
          <List
            itemLayout="horizontal"
            dataSource={menusTransformados}
            renderItem={item => (
              <List.Item style={{ display: 'list-item', borderBottom: '0px' }}>
                <List.Item.Meta
                  title={
                    item.URI ? (
                      <a
                        onClick={() => {
                          handleRedirect(item);
                        }}
                      >
                        {item.Titulo}
                      </a>
                    ) : (
                      item.Titulo
                    )
                  }
                />
                <Divider
                  style={{
                    margin: '0px',
                    color: corTema,
                    borderTop: '1px solid',
                    width: '95%',
                  }}
                />
                {item.SubItens.length > 0 && (
                  <ul
                    style={{ listStyleType: 'none', paddingInlineStart: '0px' }}
                  >
                    {item.SubItens.map(sub => (
                      <li key={sub.Id} style={{ color: corTema }}>
                        {sub.URI ? (
                          <a
                            style={{ color: corTema }}
                            onClick={() => {
                              handleRedirect(sub);
                            }}
                          >
                            {sub.Titulo}
                          </a>
                        ) : (
                          <span style={{ color: corTema }}>{sub.Titulo}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </List.Item>
            )}
          />
        </Card>
      </Col>
      <Row>
        <ButtonGoBack OnClick={goBack} />
      </Row>
    </>
  );
}

export default Sumario;
