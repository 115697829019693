import React from 'react';
import { Menu } from 'antd';
import { Limite } from '~/modules/Helper/FileExportConstants';
import { Container } from './Exportacao.styled';

import Tooltip from '../Tooltip/Tooltip.component';
import Dropdown from '../Dropdown/Dropdown.component';
import Icons from '../Icons/Icons.component';
import Button from '../Button/Button.component';

function Exportacao({ onClick, QuantidadeRegistros, semURI, moduloFixo }) {
  function handleMenuClick(e) {
    onClick(e.key);
  }

  function handleLimitMessage(limite) {
    return `${`Não é possível exportar acima de ${limite} registros`}`;
  }

  const menuExportacao = (
    <Menu onClick={handleMenuClick}>
      {!moduloFixo && (
        <Menu.Item
          key="pdflandscape"
          disabled={QuantidadeRegistros > Limite.PDF}
        >
          <Tooltip
            title={
              QuantidadeRegistros > Limite.PDF && handleLimitMessage(Limite.PDF)
            }
            placement="left"
          >
            <div>
              <Icons.FilePdfOutlined style={{ fontSize: 20, marginRight: 5 }} />
              PDF
            </div>
          </Tooltip>
        </Menu.Item>
      )}

      <Menu.Item key="csv" disabled={QuantidadeRegistros > Limite.CSV}>
        <Tooltip
          title={
            QuantidadeRegistros > Limite.CSV && handleLimitMessage(Limite.CSV)
          }
          placement="left"
        >
          <div>
            <Icons.FileExcelOutlined style={{ fontSize: 20, marginRight: 5 }} />
            CSV
          </div>
        </Tooltip>
      </Menu.Item>

      <Menu.Item key="xlsx" disabled={QuantidadeRegistros > Limite.XLSX}>
        <Tooltip
          title={
            QuantidadeRegistros > Limite.XLSX && handleLimitMessage(Limite.XLSX)
          }
          placement="left"
        >
          <div>
            <Icons.FileExcelOutlined style={{ fontSize: 20, marginRight: 5 }} />
            XLSX
          </div>
        </Tooltip>
      </Menu.Item>

      <Menu.Item key="txt" disabled={QuantidadeRegistros > Limite.TXT}>
        <Tooltip
          title={
            QuantidadeRegistros > Limite.TXT && handleLimitMessage(Limite.TXT)
          }
          placement="left"
        >
          <div>
            <Icons.FileTextOutlined style={{ fontSize: 20, marginRight: 5 }} />
            TXT
          </div>
        </Tooltip>
      </Menu.Item>

      {!'RefatorarRotina' && !moduloFixo && (
        <Menu.Item key="jpg" disabled={QuantidadeRegistros > Limite.JPG}>
          <Tooltip
            title={
              QuantidadeRegistros > Limite.JPG && handleLimitMessage(Limite.JPG)
            }
            placement="left"
          >
            <div>
              <Icons.FileJpgOutlined style={{ fontSize: 20, marginRight: 5 }} />
              JPG
            </div>
          </Tooltip>
        </Menu.Item>
      )}

      <Menu.Item key="xml" disabled={QuantidadeRegistros > Limite.XML}>
        <Tooltip
          title={
            QuantidadeRegistros > Limite.XML && handleLimitMessage(Limite.XML)
          }
          placement="left"
        >
          <div>
            <Icons.FileTextOutlined style={{ fontSize: 20, marginRight: 5 }} />
            XML
          </div>
        </Tooltip>
      </Menu.Item>

      {!semURI && (
        <Menu.Item key="uri">
          <Icons.LinkOutlined style={{ fontSize: 20, marginRight: 5 }} />
          URI
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Container>
      <Dropdown trigger={['click']} overlay={menuExportacao}>
        <Button style={{ padding: '0px 10px 0px 10px' }}>
          Exportar para <Icons.DownOutlined />
        </Button>
      </Dropdown>
    </Container>
  );
}

export default Exportacao;
