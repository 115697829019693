/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { useBreadcrumb } from '~/hooks/useBreadcrumb';
import * as S from './Breadcrumb.styled';

function Breadcrumb({ master, match }) {
  //
  const { params, path } = match;
  const { obterBreadcrumbPorUri } = useBreadcrumb();
  const breadcrumb = obterBreadcrumbPorUri(path, params);

  if (!breadcrumb?.breadcrumb) return null;

  return (
    <S.Breadcrumb>
      <S.Breadcrumb.Item>
        <Link to={master}>
          <HomeOutlined />
        </Link>
      </S.Breadcrumb.Item>
      {breadcrumb.breadcrumb.map((item, index) =>
        breadcrumb.breadcrumbUri &&
        index === breadcrumb.breadcrumb.length - 2 ? (
          <S.Breadcrumb.Item key={Math.random()}>
            <Link to={breadcrumb.breadcrumbUri}>{item}</Link>
          </S.Breadcrumb.Item>
        ) : (
          <S.Breadcrumb.Item key={Math.random()}>{item}</S.Breadcrumb.Item>
        )
      )}
    </S.Breadcrumb>
  );
}

export default Breadcrumb;

Breadcrumb.defaultProps = {
  breadcrumb: [],
};
