import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Pagination, notification } from 'antd';
import { Select, Exportacao } from '~/components';
import { UltimaAtualizacao } from './UltimaAtualizacao.component';
import { fileManagerPaiDownload } from '~/services/FileManager/file-manager.service';
import { Container, NumberRegistersArea, ButtonsArea } from './Footer.styled';
import { exportarVisao } from '../../services/moduloVisao.service';
import { exportarBuscaAvancada } from '~/modules/BuscaAvancada/services/buscaAvancada.service';
import { getUrlComDominio } from '~/modules/Helper/getUrl-helper';

function Footer({
  moduloVisao,
  quantidadeRegistros,
  setQuantidadeRegistros,
  page,
  setPage,
  configuracao,
  modulo,
  visao,
  filter,
  periodicidade,
  periodo,
  exercicio,
  colunaFiltroRedirecionaVisao,
  valorFiltroRedirecionaVisao,
  tipoValorFiltroRedirecionaVisao,
  dataUltimaAtualizacao,
  buscaAvancada,
}) {
  const [tipo, setTipo] = useState(null);

  const configuration = useSelector(state => state.configuration);
  const { corFundoTituloGrid, corTituloGrid } = configuration;

  function setNewTipo() {
    if (configuracao.Tipo !== tipo) {
      setTipo(configuracao.Tipo);
    }
  }

  useEffect(() => {
    setTipo(configuracao.Tipo);
    setNewTipo();
  }, [configuracao.Tipo, setNewTipo]);

  function exportar(tipoExportacao) {
    if (tipoExportacao === 'uri') {
      navigator.clipboard.writeText(window.location.href);
      notification.success({
        message: 'URI Copiada com Sucesso!',
      });
    } else {
      const FiltroRedirecionaVisao = {
        Campo: colunaFiltroRedirecionaVisao,
        Valor: valorFiltroRedirecionaVisao,
        TipoValor: tipoValorFiltroRedirecionaVisao,
      };

      const dtoExportacao = {
        ChaveModulo: modulo,
        NomeVisao: visao,
        Filtros: filter,
        Periodicidade: periodicidade,
        Periodo: periodo,
        Exercicio: exercicio,
        Pagina: page,
        QuantidadeRegistros: quantidadeRegistros,
        FiltroRedirecionaVisao,
        UrlExportacao: getUrlComDominio(),
      };

      if (buscaAvancada) {
        exportarBuscaAvancada(tipoExportacao, dtoExportacao).then(res => {
          fileManagerPaiDownload(res.data);
        });
      } else {
        exportarVisao(tipoExportacao, dtoExportacao).then(res => {
          fileManagerPaiDownload(res.data);
        });
      }
    }
  }

  return (
    <Container>
      <div>
        <NumberRegistersArea
          corFundoTituloGrid={corFundoTituloGrid}
          corTituloGrid="#000"
        >
          <Pagination
            current={page}
            showSizeChanger={false}
            pageSize={quantidadeRegistros}
            total={moduloVisao.QuantidadeRegistros}
            showTotal={total => {
              return (
                <p style={{ color: corTituloGrid }}>
                  <b>Total: </b>
                  {`${new Intl.NumberFormat('pt-BR').format(total)} registros`}
                </p>
              );
            }}
            onChange={pag => setPage(pag)}
          />

          <Select
            placeholder="Quantidade de Registros"
            value={quantidadeRegistros}
            onChange={qtde => {
              setPage(1);
              setQuantidadeRegistros(qtde);
            }}
            style={{ color: '#000' }}
          >
            {[5, 10, 20, 50, 100].map(quantidade => (
              <Select.Option
                key={quantidade}
                value={String(quantidade)}
                style={{ color: '#000' }}
              >
                {quantidade} registros
              </Select.Option>
            ))}
          </Select>
        </NumberRegistersArea>
      </div>

      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        {dataUltimaAtualizacao && (
          <UltimaAtualizacao
            dataHora={dataUltimaAtualizacao}
            corTituloGrid={corTituloGrid}
          />
        )}
      </div>

      <ButtonsArea corTituloGrid={corTituloGrid}>
        {moduloVisao != null && moduloVisao.Valores.length !== 0 && (
          <Exportacao
            onClick={exportar}
            QuantidadeRegistros={moduloVisao.QuantidadeRegistros}
          />
        )}
      </ButtonsArea>
    </Container>
  );
}

export default Footer;
