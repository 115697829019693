import { useEffect, useState } from 'react';
import { useMenus } from '~/contexts/menu.context';
import { TIPO_MENU_PORTAL } from '~/modules/Helper/tipoMenuPortal';
import {
  breadcrumbEstruturaOrganizacional,
  breadcrumbEsic,
  breadcrumbFaq,
  breadcrumbDadosAbertos,
  breadcrumbGlossario,
  breadcrumbHome,
  breadcrumbSumario,
  breadcrumbFaleConosco,
  breadcrumbEstatisticas,
  breadcrumbAutenticidade,
} from '~/utils/breadcrumb';

export function useBreadcrumb() {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const { menus, menuSelecionado, setMenuSelecionado } = useMenus();

  function obterBreadcrumbModulos() {
    // eslint-disable-next-line no-shadow
    function processarItens(itens, caminhoAnterior = [], breadcrumbs = []) {
      itens.forEach(item => {
        const novoBreadcrumb = [...caminhoAnterior, item.Titulo];

        if (
          item.tipoMenuPortal !== TIPO_MENU_PORTAL.EXTERNO &&
          item.TipoMenuPortal !== TIPO_MENU_PORTAL.ITEM_MENU &&
          item.URI
        ) {
          breadcrumbs.push({
            id: item.Id,
            uri: item.URI,
            breadcrumb: novoBreadcrumb,
          });
          if (item.URI.includes('/dinamico')) {
            breadcrumbs.push({
              id: item.Id,
              uri: `${item.URI}/detalhevisao`,
              breadcrumb: [...novoBreadcrumb, 'Detalhe Visão Analítica'],
              breadcrumbUri: item.URI,
            });
            breadcrumbs.push({
              id: item.Id,
              uri: `${item.URI}/busca`,
              breadcrumb: [...novoBreadcrumb, 'Busca Avançada'],
              breadcrumbUri: item.URI,
            });
          }
        }

        if (item.SubItens && item.SubItens.length) {
          processarItens(item.SubItens, novoBreadcrumb, breadcrumbs);
        }
      });

      return breadcrumbs;
    }

    return processarItens(menus);
  }

  useEffect(() => {
    const breadcrumbModulos = obterBreadcrumbModulos();

    const newBreadcrumb = [
      ...breadcrumbModulos,
      ...breadcrumbEstruturaOrganizacional,
      ...breadcrumbFaq,
      ...breadcrumbDadosAbertos,
      ...breadcrumbEsic,
      ...breadcrumbSumario,
      ...breadcrumbGlossario,
      ...breadcrumbHome,
      ...breadcrumbFaleConosco,
      ...breadcrumbEstatisticas,
      ...breadcrumbAutenticidade,
    ];

    setBreadcrumbs(newBreadcrumb);
  }, [menus]);

  function obterBreadcrumbPorUri(uri, params) {
    //
    if (!breadcrumbs?.length) return undefined;

    //
    if (menuSelecionado) {
      const breadcrumb = breadcrumbs.find(x => {
        return x.id === menuSelecionado.Id;
      });
      setMenuSelecionado(null);
      return breadcrumb;
    }

    //
    const { modulo, visao } = params;
    let uriComparacao = uri;
    if (uri.includes('/dinamico/') || uri.includes('/fixo/')) {
      uriComparacao = uri.replace(':modulo', modulo).replace(':visao', visao);
    }
    return breadcrumbs.find(x => {
      return x.uri === uriComparacao;
    });
  }

  return { breadcrumbs, obterBreadcrumbPorUri };
}
