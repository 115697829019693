export const breadcrumbEstruturaOrganizacional = [
  {
    uri: '/estrutura_organizacional',
    breadcrumb: ['Estrutura Organizacional'],
  },
  {
    uri: '/estrutura_organizacional/detalhe/:id/',
    breadcrumb: [
      {
        breadcrumb: 'Estrutura Organizacional',
        uri: '/estrutura_organizacional',
      },
      'Detalhes',
    ],
  },
];

export const breadcrumbHome = [{ uri: '/', breadcrumb: ['Home'] }];

export const breadcrumbFaq = [{ uri: '/faq', breadcrumb: ['FAQ'] }];

export const breadcrumbDadosAbertos = [
  { uri: '/dados_abertos', breadcrumb: ['Dados Abertos'] },
];

export const breadcrumbEsic = [{ uri: '/esic', breadcrumb: ['e-SIC'] }];

export const breadcrumbSumario = [{ uri: '/sumario', breadcrumb: ['Sumário'] }];

export const breadcrumbGlossario = [
  { uri: '/glossario', breadcrumb: ['Glossário'] },
];

export const breadcrumbFaleConosco = [
  { uri: '/faleconosco', breadcrumb: ['Fale Conosco'] },
];

export const breadcrumbEstatisticas = [
  { uri: '/estatisticas', breadcrumb: ['Estatísticas'] },
];

export const breadcrumbAutenticidade = [
  { uri: '/autenticidade', breadcrumb: ['Autenticidade'] },
];
