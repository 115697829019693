import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { InfoManutencao } from './EmManutencao.styled';
import { Button } from '../Button/Button.styled';
import * as ModuloVisaoService from '../../modules/Modulos/services/moduloVisao.service';

function EmManutencao() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const visao = params.get('visao');
  const titulo = params.get('titulo');
  const [moduloApi, visaoApi] = visao.split('/');
  const [botaoDisabled, setBotaoDisabled] = useState(true);

  useEffect(() => {
    const voltarParaVisao = async () => {
      const responseConfiguracao = await ModuloVisaoService.obterConfiguracoes(
        moduloApi,
        visaoApi
      );

      const modoVisao = responseConfiguracao.data.ModoVisao;
      if (modoVisao !== 3) {
        setBotaoDisabled(false);
      }
    };

    voltarParaVisao();
  }, [moduloApi, visaoApi]);

  const voltarParaVisao = () => {
    if (botaoDisabled) history.push('/');
    else history.push(`/${visao}`);
  };

  const textoBotao = botaoDisabled
    ? 'Voltar para a Home'
    : 'Voltar para a Visão';

  const tituloPagina = botaoDisabled
    ? `Visão '${titulo}' em manutenção temporária.`
    : `Visão '${titulo}' sofreu uma alteração.`;

  const subTituloPagina = botaoDisabled
    ? 'Tente novamente mais tarde.'
    : 'Clique aqui para atualizar';

  return (
    <>
      <InfoManutencao
        status="info"
        title={tituloPagina}
        subTitle={subTituloPagina}
        extra={
          <Button type="primary" onClick={voltarParaVisao}>
            {textoBotao}
          </Button>
        }
      />
    </>
  );
}

export default EmManutencao;
