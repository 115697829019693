import { createGlobalStyle } from 'styled-components';

const highContrastColors = {
  background: 'black',
  color: 'yellow',
};

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Segoe UI' !important;

    ::placeholder {
      color: ${props =>
        props.theme.highContrast && highContrastColors.color} !important;  
    }

    ::selection {
      background-color: ${props =>
        props.theme.highContrast && highContrastColors.color} !important;
    }

    background-color: ${props =>
      props.theme.highContrast && highContrastColors.background} !important;

    border-color: ${props =>
      props.theme.highContrast && highContrastColors.color} !important;

    color: ${props =>
      props.theme.highContrast && highContrastColors.color} !important;
  }
  
  .ant-table-footer{
    padding: 14px 14px;
  }

  &&.access-button{
    width: 23.5% !important
  }

  .maxHeight500 {
    max-height: 500px;
  }

  // Barra de rolagem com fundo transparente
  
  /* Estilo para navegadores baseados em WebKit (Chrome, Edge, Safari) */
  ::-webkit-scrollbar {
    width: 8px; /* Largura da barra de rolagem */
    height: 8px; /* Altura da barra de rolagem (para rolagem horizontal) */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* Fundo sempre transparente */
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5); /* "Polegar" da barra sem fundo branco */
    border-radius: 4px; /* Bordas arredondadas */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5); /* Mesma cor ao passar o mouse */
  }

  /* Estilo para navegadores Firefox */
  * {
    scrollbar-width: thin; /* Faz a barra de rolagem ficar fina */
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* "Polegar" com cor e trilha transparente */
  }

`;

export default GlobalStyle;
